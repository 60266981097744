<template>
  <div style="border: 1px solid #ccc; z-index: 99">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden"
      v-model="editorContent"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      ref="editor"
      @input="handleEditorChange"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { uploadRequest } from "@/components/aliyun";
import OSS from "ali-oss";
import { Message } from "element-ui";
import { toolsts } from "@/api";
import { guid } from "@/utils/other.js";
import ObsClient from "esdk-obs-browserjs";
import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  DomEditor,
} from "@wangeditor/editor";
// const toolbar = DomEditor.getToolbar(editor)
// console.log(DomEditor.getToolbar(this.editor),'DomEditorDomEditor');
export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    details: {
      type: String, //在props接受父组件传递数据
      default: "",
    },
    disabled: {
      type: Boolean, //是否禁用 只读
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: {
        placeholder: "请输入内容...",
        readOnly: this.disabled,
        autoFocus: false,
        // hoverbarKeys:[], //配置编辑器的 hoverbar 菜单。 鼠标指向悬浮菜单
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            async customUpload(file, insertFn) {
              const isLt5M = file.size / 1024 / 1024 < 20;
              if (!isLt5M) {
                Message.error("上传图片不能大于20M!");
                return;
              }
              let pos = file.name.lastIndexOf(".");
              let extendName = file.name.substring(pos + 1);
              let fileNames = `backstage/${guid()}.${extendName}`; // 拼接文件名，保证唯一，这里使用时间戳+原文件名
              let obsClient;
              toolsts().then(async (res) => {
                if (res.code === 200) {
                  const {
                    access,
                    secret,
                    bucket,
                    endpoint,
                    securityToken,
                    ak,
                    sk,
                  } = res.data;
                  obsClient = new ObsClient({
                    access_key_id: ak,
                    secret_access_key: sk,
                    server: `https://${endpoint}`,
                    x_obs_acl: "public-read",
                  });
                  obsClient.putObject(
                    {
                      Bucket: bucket,
                      Key: fileNames,
                      // SourceFile: file, // localfile为待上传的本地文件路径，需要指定到具体的文件名
                      Body: file,
                      ACL: "public-read",
                    },
                    (err, result) => {
                      if (err) {
                        this.$message.error("华为云上传失败");
                        this.$emit("upload", this.url);
                        this.showUpLoad = false;
                        return Promise.reject(e);
                      } else {
                        if (result.CommonMsg.Status == 200) {
                          // 上传之后的文件地址
                          let restImg = `https://${bucket}.${endpoint}/${fileNames}`;
                          res = restImg;
                          insertFn(res);
                        }
                      }
                    }
                  );
                } else {
                  this.$message.error(res.msg);
                }
              });
              // let url = uploadRequest(file);
              // console.log(url, "filefilefile");

              return;
            },
          },
          uploadVideo: {
            // 自定义上传
            async customUpload(file, insertFn) {
              const isLt5M = file.size / 1024 / 1024 < 300;
              if (!isLt5M) {
                Message.error("上传视频不能大于300M!");
                return;
              }
              let url = uploadRequest(file);
              insertFn(url);
              return;
            },
          },
        },
      },
      mode: "default", // or 'simple'
      editorContent: "", //编辑器值
    };
  },
  watch: {
    details(newName, oldName) {
      // console.log(newName,oldName, "newNamenewName");
      this.editorContent = newName;
    },
    immediate: true,
    // deep: true,
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错

      const curToolbarConfig = editor.getConfig();
      // console.log(curToolbarConfig.toolbarKeys); // 当前菜单排序和分组
      this.toolbarConfig = {
        ...curToolbarConfig,
        excludeKeys: [
          ...new Set([
            "fontFamily",
            "group-video",
            "insertLink",
            "insertTable",
            ...(curToolbarConfig.excludeKeys ?? []),
          ]),
        ],
      };
    },
    handleToolbarChange() {
      // 处理工具栏变化事件
    },
    handleEditorChange(content) {
      // 处理编辑器内容变化事件
      // console.log(this.editor.getAllMenuKeys(), "this.editorthis.editor");
      this.$emit("editoChange", this.editorContent);
    },
    onChange(editor) {
      const toolbar = DomEditor.getToolbar(editor);
      console.log("工具栏配置", toolbar.getConfig().toolbarKeys); // 工具栏配置的所有key，放在excludeKeys里即可隐藏
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    Vue.nextTick(() => {
      this.editorContent = this.details;
    });
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
.ElImage {
  width: 148px !important;
  height: 148px !important;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.ElImage .ElImageItem {
  width: 148px !important;
  height: 148px !important;
}
.ElImageHover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
}
.ElImageHover:hover {
  opacity: 1;
}
.ElIcon {
  color: #fff;
  font-size: 20px;
}
::v-deep .el-image-viewer__wrapper {
  /* z-index: 9999!important; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
